
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
// import EditCampana from "./edit-campana.vue";
import PeopleFind from "../../../components/People/people-find.vue";
import Campana from "../../../store/entities/OZONE/campana";
import Cliente from "../../../store/entities/OZONE/cliente";
import Exportacion from "../../../store/entities/OZONE/exportacion";
import Transferencia from "../../../store/entities/OZONE/transferencia";
import WidgetCounter from "../../../components/Muse/Widgets/WidgetCounter.vue";
import EditarTransferencia from "./edit-transferencia.vue";
import moment, { Moment } from "moment";

class PageTransferenciaRequest extends PageRequest {
  keyword: string;
  fechaInicio: Date;
  fechaFin: Date;
  campana: Campana;
  cliente: Cliente;
  exportacion: Exportacion;
  enviada: Boolean;
  emitida: Boolean;
  pendiente: Boolean;
  rechazada: Boolean;
  ids: Array<number>;
  min: number;
  max: number;
}

@Component({
  components: {
    PeopleFind,
    WidgetCounter,
    EditarTransferencia,
  },
})
export default class Transferencias extends AbpBase {
  //filters
  sort: string = "CreationTime desc";
  pagerequest: PageTransferenciaRequest = new PageTransferenciaRequest();
  creationTime: Date[] = [];
  authorsHeaderBtns: String = "all";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  findModalShow: boolean = false;
  clienteNombre: string = "";

  fechaDevolucion: Moment = null;

  transferencia: Transferencia = null;
  editModalShowTitle: string = null;
  editModalShowAction: string = null;

  selectedTransferencias: Array<number> = [];
  currentSearch: { key: string; value: string; label: string }[] = [];

  onChange = (e) => {
    var id = e.target.id;
    var value = e.target.checked;
    console.log(id);
    if (value) {
      this.selectedTransferencias.push(id);
    } else {
      const index = this.selectedTransferencias.indexOf(id);
      if (index > -1) {
        this.selectedTransferencias.splice(index, 1);
      }
    }
  };

  campana: Campana = null;
  cliente: Cliente = null;
  exportacion: Exportacion = null;

  searchDefinition: {}[] = [
    {
      fieldName: "Busqueda",
      fieldType: "text",
      fieldBind: "keyword",
      value: "",
    },
    {
      fieldName: "Fecha Inicio",
      fieldType: "date",
      fieldBind: "fechaInicio",
      value: "",
    },
    {
      fieldName: "Fecha Fin",
      fieldType: "date",
      fieldBind: "fechaFin",
      value: "",
    },
    {
      fieldName: "Campaña",
      fieldType: "custom",
      custom: "select-campana",
      fieldBind: "campana",
      value: this.campana,
    },
    {
      fieldName: "Cliente",
      fieldType: "custom",
      custom: "select-cliente",
      fieldBind: "cliente",
      value: this.cliente,
    },
    {
      fieldName: "Exportación SEPA",
      fieldType: "custom",
      custom: "select-exportacion",
      fieldBind: "exportacion",
      value: this.exportacion,
    },
    {
      fieldName: "Pendiente",
      fieldType: "checkbox",
      fieldBind: "pendiente",
      value: false,
    },
    {
      fieldName: "Enviada",
      fieldType: "checkbox",
      fieldBind: "enviada",
      value: false,
    },
    {
      fieldName: "Emitida",
      fieldType: "checkbox",
      fieldBind: "emitida",
      value: false,
    },
    {
      fieldName: "Rechazada",
      fieldType: "checkbox",
      fieldBind: "rechazada",
      value: false,
    },
    {
      fieldName: "Importe Mínimo",
      fieldType: "number",
      fieldBind: "min",
      value: 0,
    },
    {
      fieldName: "Importe Máximo",
      fieldType: "number",
      fieldBind: "max",
      value: 0,
    },
  ];

  submitTransferencia = () => {
    
  };

  pagination: any = {};

  get totalCampanas() {
    
    return {
      title: "Campañas",
      valuee: this.$store.state.transferencia.totalCampanas,
      prefix: "",
      //suffix: this.$store.state.transferencia.dashboard.rechazadas.cantidad,
      icon: `<i class="fa fa-comments-dollar" style="color:white"></i>`,
      status: "danger",
    };
  }
  get totalImporte() {
    return {
      title: "Total",
      valuee: this.$store.state.transferencia.totalImporte,
      prefix: "€",
      //suffix: this.$store.state.transferencia.dashboard.rechazadas.cantidad,
      icon: `<i class="fa fa-euro-sign" style="color:white"></i>`,
      status: "danger",
    };
  }
  get totalTransferencias() {
    return {
      title: "Transferencias",
      valuee: this.$store.state.transferencia.totalCount,
      prefix: "",
      //suffix: this.$store.state.transferencia.dashboard.rechazadas.cantidad,
      icon: `<i class="fa fa-exchange-alt" style="color:white"></i>`,
      status: "danger",
    };
  }

  get list() {
    return this.$store.state.transferencia.list;
  }
  get loading() {
    return this.$store.state.transferencia.loading;
  }

  getSearch = async (model) => {
      this.currentSearch.length = 0;

    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
        this.currentSearch.push({
          label: element.fieldName,
          key: element.fieldBind,
          value:
            element.fieldType === "custom"
              ? element.value[element.display]
              : element.fieldType == 'checkbox' ? '' : element.value,
        });
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });

    this.$store.commit("transferencia/setCurrentPage", 1);
    await this.getpage();
    this.findModalShow = false;
  };

    async deleteFilter(ixFilter) {
    var filter = this.currentSearch[ixFilter];
    delete this.pagerequest[filter.key];
    (
      this.$refs.peoplefind as Vue & { resetField: (key: string) => void }
    ).resetField(filter.key);
    await this.getpage();
  }

  async reset() {
    (this.$refs.peoplefind as Vue & { reset: () => void }).reset();
    this.currentSearch.length = 0;
  }

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  reenviar(row) {
    this.editModalShow = true;
    this.editModalShowTitle = "Reenviar Transferencia";
    this.editModalShowAction = "redo";
    this.$store.commit("transferencia/edit", row);
  }

  forzar(row) {
    this.editModalShow = true;
    this.editModalShowTitle = "Forzar Transferencia";
    this.editModalShowAction = "force";
    this.$store.commit("transferencia/edit", row);
  }

  async aprobar(row) {
    await this.$store
      .dispatch({
        type: "transferencia/aprobar",
        data: { id: row.id },
      })
      .then(async (data) => {
        this.$message.success("Transferencia aprobada! Se lanzará en el siguiente proceso");
        await this.getpage();
      });
  }
  
  async desaprobar(row) {
    await this.$store
      .dispatch({
        type: "transferencia/desaprobar",
        data: { id: row.id },
      })
      .then(async (data) => {
        this.$message.success("Transferencia desaprobada!");
        await this.getpage();
      });
  }
  

  editar(row) {
    this.editModalShow = true;
    this.editModalShowTitle = "Editar Transferencia";
    this.editModalShowAction = "edit";
    this.$store.commit("transferencia/edit", row);
  }

  async park(row) {
    await this.$store
      .dispatch({
        type: "transferencia/aparcar",
        data: { id: row.id },
      })
      .then(async (data) => {
        this.$message.success("Transferencia aparcada!");
        await this.getpage();
      });
  }

  async unpark(row) {
    await this.$store
      .dispatch({
        type: "transferencia/reanudar",
        data: { id: row.id },
      })
      .then(async (data) => {
        this.$message.success("Transferencia reanudada!");
        await this.getpage();
      });
  }

  async emitirUrgente(row) {
    this.$message.loading("Generando fichero SEPA...");
    let transferenciaID = row.id;
    if (transferenciaID) {
      await this.$store
        .dispatch({
          type: "transferencia/urgent",
          data: { id: transferenciaID },
        })
        .then(async (data) => {
          this.$message.success("Transferencia emitida!");
          await this.getpage();
        });
    } else {
      this.$message.error("Error al generar SEPA", 5);
    }
  }
  pageChange(page: number) {
    this.$store.commit("transferencia/setCurrentPage", page);
    this.getpage();
  }

  pagesizeChange(pagesize: number) {
    this.$store.commit("transferencia/setPageSize", pagesize);
    this.getpage();
  }

  async download() {
    this.pagerequest.ids = this.selectedTransferencias;
    await this.$store.dispatch({
      type: "transferencia/downloadAll",
      data: this.pagerequest,
    });
    let download = this.$store.state.transferencia.download;

    var fileName = "Transferencias_" + new Date().toISOString() + ".csv";

    let blob = this.dataURLtoFile("data:text/csv;base64," + download, fileName);
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;
    this.pagerequest.sorting = this.sort;

    await this.$store.dispatch({
      type: "transferencia/getAll",
      data: this.pagerequest,
    });
    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.transferencia.totalCount;
    pagination.showTotal = () =>
      `${this.$store.state.transferencia.totalCount} transferencias.`;
    pagination.pageSize = this.$store.state.transferencia.pageSize;
    pagination.current = this.$store.state.transferencia.currentPage;
     pagination.showSizeChanger = true;
    pagination.pageSizeOptions = ['10', '50', '100']
    this.pagination = pagination;
  }

  get pageSize() {
    return this.$store.state.transferencia.pageSize;
  }

  get totalCount() {
    return this.$store.state.transferencia.totalCount;
  }

  get currentPage() {
    return this.$store.state.transferencia.currentPage;
  }

  set currentPage(page) {
    this.$store.commit("transferencia/setCurrentPage", page);
    this.getpage();
  }

  handleTableChange = async (pagination, filters, sorter) => {
    
    this.$store.commit("transferencia/setCurrentPage", pagination.current);
    this.$store.commit("transferencia/setPageSize", pagination.pageSize);
  

    await this.getpage();
  };

  async rechazar(transfer) {
    if (this.fechaDevolucion) {
      await this.$store
        .dispatch({
          type: "transferencia/rechazar",
          data: {
            id: transfer.id,
            fechaDevolucion: this.fechaDevolucion.format("YYYY/MM/DD"),
          },
        })
        .then(async () => {
          this.$message.success("Transferencia marcada como rechazada");
          await this.getpage();
        });
    } else {
      this.$message.error("Debe seleccionar una fecha");
    }
  }

  async getCliente(clienteId) {
    var cliente = "";
    var result = this.$store.dispatch({
      type: "transferencia/get",
      id: clienteId,
    });
    return this.$store.state.cliente.editCliente.cliente.nombre;
  }

  irParticipacion(item) {
    this.$store.commit("participacion/edit", { id: item.participacionId });
    this.$router.push({
      name: "editparticipacion",
      params: { id: item.participacionId },
    });
  }

  stateColorScheme(state: number) {
    switch (state) {
      case 1:
        return "ant-tag-primary";
      case 3:
        return "ant-tag-secondary";
      case 4:
        return "ant-tag-success";
      case 5:
        return "ant-tag-danger";
      case 6:
        return "ant-tag-warning";
    }
  }

  columns = [
    {
      title: this.L(""),
      dataIndex: "id",
      scopedSlots: { customRender: "check" },
    },
    {
      title: this.L("Participacion"),
      scopedSlots: { customRender: "func" },
    },
    {
      title: this.L("IBAN"),
      dataIndex: "iban",
    },
    {
      title: this.L("Importe"),
      dataIndex: "importe",
      sorter: true,
      scopedSlots: { customRender: "money" },
    },
    {
      title: this.L("Estado"),
      scopedSlots: { customRender: "status" },
    },
    {
      title: this.L("Creada"),
      dataIndex: "fechaCreacion",
      sorter: true,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Enviada"),
      dataIndex: "fechaEnvio",
      sorter: true,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Emitida"),
      dataIndex: "fechaEmision",
      sorter: true,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Rechazada"),
      dataIndex: "fechaDevolucion",
      sorter: true,
      scopedSlots: { customRender: "date" },
    },
    // {
    //   scopedSlots: { customRender: "editBtn" },
    //   width: 5,
    //   title: "",
    //   fixed: "right",
    // },
    // {
    //   scopedSlots: { customRender: "urgentBtn" },
    //   width: 5,
    //   title: "",
    //   fixed: "right",
    // },
    // {
    //   scopedSlots: { customRender: "parkBtn" },
    //   width: 5,
    //   title: "",
    //   fixed: "right",
    // },
    {
      scopedSlots: { customRender: "actions" },
      title: "Acciones",
      fixed: "right",
    },
  ];

  async created() {
    if (this.$route.query.exp) {
      var exportacion = new Exportacion();
      exportacion.id = parseInt(this.$route.query.exp as string);
      this.pagerequest.exportacion = exportacion;
      this.exportacion = exportacion;
    }
    this.getpage();
  }
}
